function removeCityAndState(storeType) { //storeType - new or old
  if (typeof window !== 'undefined') {
    if (storeType === "new") {
      localStorage.removeItem("new_city_name");
      localStorage.removeItem("new_state_code");
    } else {
      localStorage.removeItem("city_name");
      localStorage.removeItem("state_code");
    }
  }
}

//IF CITY NAME INCLUDES A COMMA WITH STATE CODE THEN RETURN TRUE ELSE FALSE
export function checkCityName(city) {
  return city?.split(",")?.length > 1;
}

function setCityAndState(setCityName, setStateCode) {
  if (typeof window !== 'undefined') {
    /*
     * SETTING CITY AND STATE CODE IN STATES FOR REDIRECTION URLS
     *
     * 1. IF NEW_CITY_NAME ALREADY PRESENT - GIVE PRIORITY TO IT(AS IT IS THE PREVIOUS WORKFLOW AND WILL BE THE BEST OPTION TO PROCEED)
     *   NOTE - WE ARE CHECKING THE STATE_CODE FOR THE ELSE CASE WHEN NEW_CITY_NAME DOES NOT HAVE THE STATE CODE IN IT(AN EXCEPTIONAL SCENARIO)
     *          ALTHOUGH, WHEN NEW_CITY_NAME IS SET, THE STATE_CODE WILL ALWAYS BE SET UNTIL AND UNLESS SOMEONE JUST REMOVE IT MANUALLY FROM THE BROWSER
     *
     * 2. IF NOT THEN CHECK IF CITY_NAME AND STATE_CODE ARE PRESENT AND SET THE CITY AND STATE CODE ACCORDINGLY
     */
    if (
      localStorage.getItem("new_city_name") &&
      localStorage.getItem("new_state_code") &&
      localStorage.getItem("new_city_name") !== "null" &&
      localStorage.getItem("new_state_code") !== "null"
    ) {
      if (checkCityName(localStorage.getItem("new_city_name"))) {
        setCityName(
          localStorage
            .getItem("new_city_name")
            .toLowerCase()
            .split(",")[0]
            .trim()
            .replaceAll(" ", "-")
        );

        setStateCode(
          localStorage
            .getItem("new_city_name")
            .toLowerCase()
            .split(",")[1]
            .trim()
        );
      }
      else if (localStorage.getItem("new_state_code")) {
        setStateCode(localStorage.getItem("new_state_code").toLowerCase().trim());
      }
      else if (localStorage.getItem("state_code")) {
        setStateCode(localStorage.getItem("state_code").toLowerCase().trim());
      }
      else {
        removeCityAndState('new');
      }
    }
    else if (localStorage.getItem("city_name") && localStorage.getItem("state_code")) {
      removeCityAndState('new');

      if (localStorage.getItem("city_name").split(",").length > 1) {
        setCityName(
          localStorage
            .getItem("city_name")
            .toLowerCase()
            .split(",")[0]
            .trim()
            .replaceAll(" ", "-")
        );
        setStateCode(localStorage.getItem("state_code").toLowerCase().trim());
      }
      else {
        removeCityAndState('old');
      }

    }
  }
}

export default setCityAndState;