import React, { useEffect, useState } from "react";
import Downshift from "downshift";
import { waitForKeypress } from "./utils";
import getListingData from "./getListingData";

export default function CategorySearchComponent(props) {
  const {
    placeholder,
    error,
    label,
    defaultSearchValue,
    setDefaultSearchValue,
    setSelected,
    handleSubmit,
    unSetError,
  } = props;
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState(defaultSearchValue.value);
  const [focus, setfocus] = useState(false);
  const [searchCatValue, setSearchCatValue] = useState(
    defaultSearchValue.value
  );

  useEffect(() => {
    if (searchValue.length <= 2) {
      setItems([]);
    }
  }, [searchValue]);

  return (
    <div className="search-component category-search">
      <Downshift
        isOpen={focus}
        onInputValueChange={(inputValue) => {
          setfocus(true);
          setSearchValue(inputValue);

          //remove spaces from input value
          let inputValueNoSpace = inputValue?.replace(/\s/g, "");
          //remove spaces from default value
          let defaultValueNoSpace = searchCatValue?.replace(/\s/g, "");

          if (inputValueNoSpace === defaultValueNoSpace) {
            setfocus(false);
            return;
          }

          if (inputValue.length <= 2) {
            setItems([]);
            return;
          } else if (inputValue.length > 2) {
            waitForKeypress(100, () =>
              getListingData(inputValue).then((result) => {
                setItems(result);
              })
            );
          }
        }}
        inputValue={searchValue}
        itemToString={(item) => (item ? item?.label : "")}
        onChange={(item) => {
          if (item) {
            localStorage.setItem("slug", item.label);
          }
          setDefaultSearchValue({
            value: item?.value,
            label: item?.value,
            slug: item?.slug,
            type: item?.type,
          });
          setSelected(true);
          setfocus(false);
          setSearchCatValue(item?.value);
        }}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          clearSelection,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => {
          let listOfCategories;
          let listOfBusinesses;

          if (typeof items === "object") {
            listOfCategories = items?.find(
              (item) => item.label === "Category"
            ) || { options: [] };
            listOfBusinesses = items?.find(
              (item) => item.label === "Business"
            ) || { options: [] };
          } else {
            listOfCategories = { options: [] };
            listOfBusinesses = { options: [] };
          }

          const newDataCat = listOfCategories.options.map((item, index) => ({
            ...item,
            type: "category",
          }));
          const newDataBus = listOfBusinesses.options.map((item, index) => ({
            ...item,
            type: "business",
          }));
          const mergedData = [...newDataCat, ...newDataBus];

          return (
            <>
              <div
                className="search-component__input-container"
                {...getRootProps({}, { suppressRefError: true })}
              >
                <label>{label}</label>

                {error && <p className="form__error-msg">Required field!</p>}
                <input
                  {...getInputProps({
                    autoComplete: "off",
                    onFocus: () => {
                      setfocus(true);
                      unSetError();
                    },
                    onBlur: () => setfocus(false),
                    defaultValue: () => setDefaultSearchValue(),
                  })}
                  className="search-component__input"
                  placeholder={placeholder}
                  onKeyPressCapture={(e) => {
                    if (e.which === 13) {
                      handleSubmit();
                      setfocus(false);
                    }
                  }}
                  onInput={(e) => {
                    setDefaultSearchValue({
                      value: e.target.value,
                      label: e.target.value,
                      slug: e.target.value,
                    });
                    setSelected(false);
                    localStorage.setItem("random_slug", e.target.value);
                  }}
                  tabIndex={1}
                />
                {searchValue.length >= 1 ? (
                  <button onClick={() => clearSelection()}>
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                      tabIndex={-1}
                    ></i>
                  </button>
                ) : null}
              </div>

              <ul className="search-component__results" {...getMenuProps()}>
                {isOpen && searchValue.length >= 1 ? (
                  <>
                    {searchValue.length < 3 ? (
                      <span className="search-component__results-label">
                        Please enter 3 or more characters
                      </span>
                    ) : items.length !== 0 ? (
                      <>
                        {listOfCategories.options.length !== 0 ? (
                          <span className="search-component__results-label">
                            Categories
                          </span>
                        ) : listOfBusinesses.options.length !== 0 ? (
                          <span className="search-component__results-label">
                            Businesses
                          </span>
                        ) : null}

                        {mergedData.map((item, index) => (
                          <>
                            <li
                              className="search-component__result"
                              {...getItemProps({
                                key: index,
                                index: index,
                                item,
                                style: {
                                  backgroundColor:
                                    highlightedIndex === index
                                      ? "lightgray"
                                      : "white",
                                  fontWeight:
                                    selectedItem === item ? "bold" : "normal",
                                },
                              })}
                            >
                              {item.label}
                            </li>
                            {listOfBusinesses.options.length > 0 ? (
                              <>
                                {index ===
                                listOfCategories.options.length - 1 ? (
                                  <span className="search-component__results-label">
                                    Businesses
                                  </span>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  ""
                )}
              </ul>
            </>
          );
        }}
      </Downshift>
    </div>
  );
}
