import settings from "../settings.json";
const axios = require("axios");

export default function getSearchData(loc, e) {
  let string = loc.includes(",");

  if (string) {
    loc = loc.replace(", ", ",");
  } else {
    if (loc.split(" ").length < 1) {
      loc = loc.replace(/\s(?=\S*$)/, ",");
    }
  }

  return axios({
    url: settings.apiURL,
    method: "post",
    data: {
      query: `{
        cityByName(name: "${loc}"){
          id
          city
          state_code
          lat
          lng
          zip
        }
      }`,
    },
  })
    .then((res) => {
      let cityArr = [];
      res.data.data.cityByName.forEach((item) => {
        cityArr.push({
          value: `${item.city}`,
          label: `${item.city},${item.state_code}`,
          zipcode: `${item.zip}`,
          lat: `${item.lat}`,
          lng:`${item.lng}`
        });
      });
      return cityArr;
    })
    .catch((err) => {
      return err.message;
    });
}
