/* eslint array-callback-return: 0 */
/* eslint no-useless-concat: 0 */
/* eslint no-useless-escape: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { useEffect, useState, useContext } from "react";
import { navigate } from "gatsby-link";
import { LocationSearch, CategorySearch } from "./SearchComponent";
import space from "../helper/space";
import { GlobalStateContext } from "../store/GlobalContextProvider";
const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};
export default function FormInner(props) {
  const { searchSlug, city, path } = props;
  const globalState = useContext(GlobalStateContext);
  // check for city name in the URL
  // if it is, it should have a '-' in it
  let formattedCityName;

  if (city === "undefined-undefined") {
    formattedCityName = "";
  } else {
    formattedCityName = city?.split("-");
    if (city && city.indexOf("-") > -1) {
      // format the city name and use it as the default location value
      // split the city name by '-' and get the city name and state code
      // caplitalize the words in the city name
      // and make the state code all upper case
      let cityLabel = formattedCityName[0].split(" ");
      for (let word = 0; word < cityLabel.length; word++) {
        cityLabel[word] =
          cityLabel[word][0].toUpperCase() + cityLabel[word].substr(1);
      }
      formattedCityName[0] = cityLabel.join(" ");
      formattedCityName[1] = formattedCityName[1].toUpperCase();
      formattedCityName = formattedCityName.join(", ");
    }
  }

  const [selected, setSelected] = useState(true);

  let type = "";
  switch (path) {
    case "c":
      type = "category";
      break;
    case "b":
      type = "Business";
      break;
    default:
      type = "";
  }

  // default value of search slug & location
  const [defaultSearchValue, setDefaultSearchValue] = useState({
    value: searchSlug?.split("-").join(" ") || "",
    label: searchSlug || "",
    slug: searchSlug || "",
    type: type,
  });
  const [defaultValue, setDefaultValue] = useState(formattedCityName || "");
  const [showError, setShowError] = useState(false);
  const [locSelect, setLocSelect] = useState(false);

  useEffect(() => {
    if (
      !localStorage.getItem("city_name") &&
      localStorage.getItem("new_city_name")
    ) {
      formattedCityName ||
        setDefaultValue(space(localStorage.getItem("new_city_name")));
    }
  }, []);

  const queryHasError =
    (defaultSearchValue?.value?.trim() === "" ||
      defaultSearchValue?.value?.trim().length < 3) &&
    showError;

  const locationHasError = defaultValue?.trim() === "" && showError;

  const handleSubmit = (e) => {
    setShowError(true);

    if (
      searchSlug === defaultSearchValue.value &&
      formattedCityName === defaultValue
    ) {
      return;
    }
    if (
      queryHasError ||
      locationHasError ||
      defaultSearchValue.value.trim() === "" ||
      defaultValue.trim() === ""
    ) {
      return;
    }
    let valueOfSlug = null;
    let slug1 =
      defaultSearchValue.slug && defaultSearchValue.slug.trim() !== ""
        ? defaultSearchValue.slug
        : valueOfSlug;

    if (!defaultSearchValue.value.length) {
      valueOfSlug =
        localStorage.getItem("slug") || localStorage.getItem("random_slug");
      if (locSelect) {
        setSelected(true);
      }
    }
    if (defaultSearchValue.value.length > 2 || selected) {
      const searchValue = slug1
        ?.replace(/,/g, "")
        ?.replace(/\//g, "")
        ?.split(" ")
        ?.join("-");

      let city =
        localStorage.getItem("new_city_name") &&
        localStorage.getItem("new_city_name") !== "null"
          ? localStorage.getItem("new_city_name")?.split(",")[0]?.toLowerCase()
          : localStorage.getItem("city_name")?.split(",")[0]?.toLowerCase();
      let state =
        localStorage.getItem("new_state_code") &&
        localStorage.getItem("new_state_code") !== "null"
          ? localStorage.getItem("new_state_code")?.toLowerCase()
          : localStorage.getItem("state_code")?.toLowerCase();
      // on change update the localstorage to retain the value
      if (selected && defaultSearchValue.type.trim() !== "") {
        const routeRoot = defaultSearchValue.type === "category" ? "c" : "b";
        let slug1 =
          defaultSearchValue.slug && defaultSearchValue.slug.trim() !== ""
            ? defaultSearchValue.slug
            : valueOfSlug;

        let city =
          localStorage.getItem("new_city_name") &&
          localStorage.getItem("new_city_name") !== "null"
            ? localStorage
                .getItem("new_city_name")
                ?.split(",")[0]
                ?.toLowerCase()
            : localStorage.getItem("city_name")?.split(",")[0]?.toLowerCase();
        let state =
          localStorage.getItem("new_state_code") &&
          localStorage.getItem("new_state_code") !== "null"
            ? localStorage.getItem("new_state_code")?.toLowerCase()
            : localStorage.getItem("state_code")?.toLowerCase();
        let lat = localStorage.getItem("lat");
        let lng = localStorage.getItem("lng");
        {
          city && state !== "null"
            ? navigate(
                `${
                  !lat && !lng
                    ? `/${routeRoot}/${searchValue}/${city}-${state}`
                    : `/${routeRoot}/${searchValue}/${city}-${state}?${lat}-${lng}`
                }`,
                {
                  state: {
                    name: defaultSearchValue.label,
                    slug: slug1,
                    city: city,
                    state: state,
                  },
                }
              )
            : navigate(`/${routeRoot}/${searchValue}`, {
                state: {
                  name: defaultSearchValue.label,
                  slug: slug1,
                },
              });
        }
      } else {
        {
          typeof city == "undefined" && typeof state == "undefined"
            ? navigate(`/other/${searchValue}`)
            : navigate(`/other/${searchValue}/${city}-${state}`);
        }
      }
    }
  };

  return (
    <div className="d-md-flex" style={{ gap: "10px" }} autoComplete="off">
      <div className="d-md-flex search-component__wrapper">
        <CategorySearch
          placeholder="Ex. Attorneys, Plumbing ..."
          label="Business/Category"
          setDefaultSearchValue={setDefaultSearchValue}
          defaultSearchValue={defaultSearchValue}
          setSelected={setSelected}
          handleSubmit={handleSubmit}
          path={props.path}
          searchSlug={searchSlug}
          error={queryHasError}
          unSetError={() => setShowError(false)}
        />

        <LocationSearch
          placeholder="Enter city name"
          label="City"
          setDefaultValue={setDefaultValue}
          defaultValue={defaultValue}
          reset
          checkChange={(status) => {
            setLocSelect(status);
          }}
          error={locationHasError}
          unSetError={() => setShowError(false)}
          formattedCityName={formattedCityName}
        />
      </div>
      <div className="d-flex">
        <button
          className="btn btn-block"
          style={{
            borderRadius: "4px",
            minWidth: "175px",
            fontSize: "1rem",
          }}
          onClick={handleSubmit}
          tabIndex={1}
        >
          Search
        </button>
      </div>
    </div>
  );
}

export { slugify };
