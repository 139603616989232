import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Desktop from "../images/desktop-popup.jpg";
import Mobile from "../images/mobile-popup.jpg";
import detectDevice from "../helper/detectDevice";

const ControlledPopup = () => {
  const [open, setOpen] = useState(false);
  const [deviceType, setDeviceType] = useState("Desktop");
  const closeModal = () => setOpen(false);

  useEffect(() => {
    setDeviceType(detectDevice());
    const createCookie = () => {
      var expires = "";
      //  Use this if you want expiring at midnight
      // var date = new Date();
      // var midnight = new Date(
      //   date.getFullYear(),
      //   date.getMonth(),
      //   date.getDate(),
      //   23,
      //   59,
      //   59
      // );

      var distantFuture = new Date(9999, 0, 1);
      // use midnight in place of the distant future to set expiring at midnight
      expires = "; expires=" + distantFuture.toGMTString();
      document.cookie = "mobile_popup=shown" + expires + "; path=/";
    };
    const getCookie = () => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; mobile_popup=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    if (
      !getCookie() &&
      !["/mobile-app-android", "/mobile-app-ios", "/mobile-app"].includes(
        window?.location?.pathname
      )
    ) {
      setOpen(true);
      createCookie();
    }
  }, []);
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
      className="mobileModal"
    >
      {(close) => (
        <div className="modal mobileModal__wrappper">
          <button className="close" onClick={close}>
            &times;
          </button>
          <Link to="/mobile-app" onClick={close}>
            <img
              src={deviceType === "Mobile" ? Mobile : Desktop}
              alt="Popup Baner"
              className="qr-code"
            />
          </Link>
        </div>
      )}
    </Popup>
  );
};

export default ControlledPopup;
