/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "gatsby";
import Logo from "../images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/mystyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "font-awesome/css/font-awesome.min.css";
import SearchFormHead from "./searchformhead";
import returnWPUrl from "./settings.json";
import { Location } from "@reach/router"; // gatsby's dep
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../store/GlobalContextProvider";

export default function Header(props) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [toggle, setToggle] = useState(false);

  var [dcount, setDcount] = useState(0);
  var count = 0;
  useEffect(() => {
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      count = 0;
    };
  }, []);

  function timerHandler() {
    if (dcount === 0) {
      count++;

      if (count > state.maxTimer) {
        window.clearInterval(window.myInterval);

        dispatch({ type: "InActive" });
      }
    }
    setDcount(1);
  }

  // Start timer
  function startTimer() {
    if (dcount === 0) {
      if (count < state.maxTimer) {
        window.clearInterval(window.myInterval);
        count = 0;
      } else {
        window.addEventListener("scroll", scroll);
      }
      function scroll() {
        window.clearInterval(window.myInterval);
        dispatch({ type: "Active" });

        count = 0;
        window.removeEventListener("scroll", scroll);
      }
    }
    setDcount(1);
  }

  // Stop timer
  function stopTimer() {
    if (dcount === 0) {
      window.myInterval = window.setInterval(timerHandler, 1000);
    }
    setDcount(1);
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      stopTimer();
    } else {
      startTimer();
    }
  }

  function returnSearchFormClass(pathname) {
    if (["/"].includes(pathname)) {
      return "hideForm";
    }
    if (
      ["/mobile-app", "/mobile-app/", "/deals", "/deals/"].includes(pathname)
    ) {
      return "secondary-header navbar navbar-light secondary-navbar hideForm__mobile";
    }
    return "secondary-header navbar navbar-light secondary-navbar";
  }

  return (
    <>
      <header id="header" className="container-fluid header home-navbar">
        <nav className="navbar navbar-expand-lg navbar-light main-navigation">
          <div className="container-fluid nav-section">
            <Link to="/">
              <img
                src={Logo}
                style={{ maxWidth: `100%` }}
                height="58px"
                alt="Christinabluelogo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarMenu"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                document
                  .getElementById("header")
                  .classList.toggle("navRelative");
                window.scrollTo(0, 0);
              }}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarMenu">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/categories/"
                    aria-describedby="categories"
                  >
                    Categories
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/deals">
                    Deals
                  </Link>
                </li>
                <li className="nav-item dropdown-box dropdown show">
                  <a
                    className="nav-link dropdown-link btn btn-secondary dropdown-toggle"
                    href={void 0}
                    role="button"
                    onClick={() => {
                      if (window.innerWidth < 1025) {
                        if (toggle == true) {
                          setToggle(false);
                        }
                        setToggle(true);
                      }
                    }}
                    id="dropdownOne"
                    data-toggle={toggle ? "dropdown" : null}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Resources
                  </a>

                  <div
                    className="dropdown-list dropdown-menu"
                    aria-labelledby="dropdownOne"
                  >
                    <Link to="/directory">Print Directory</Link>
                    <Link to="/mobile-app">Mobile App</Link>
                    <a
                      className="songbook"
                      href="http://www.gocaroling.com/free-resources/christmas-blue-pages"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Christmas Songbook
                    </a>
                    <a
                      className="resources"
                      href={returnWPUrl.wpUrl + "refer/"}
                    >
                      Refer a Christian Business
                    </a>
                    <a
                      className="resources"
                      href={returnWPUrl.wpUrl + "newsletter/"}
                    >
                      Join our Newsletter
                    </a>
                    <a
                      className="resources"
                      href={
                        returnWPUrl.wpUrl +
                        "category/christian-business-spotlights/"
                      }
                    >
                      Christian Business Spotlights
                    </a>
                    <a
                      className="resources"
                      href={returnWPUrl.wpUrl + "church-information"}
                    >
                      Church/Ministry Resources
                    </a>
                    <a
                      className="resources"
                      href={returnWPUrl.wpUrl + "encouragement-for-life-blog/"}
                    >
                      Blogs
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown-box dropdown show">
                  <a
                    className="nav-link dropdown-link btn btn-secondary dropdown-toggle"
                    href={void 0}
                    role="button"
                    onClick={() => {
                      if (window.innerWidth < 1025) {
                        if (toggle == true) {
                          setToggle(false);
                        }
                        setToggle(true);
                      }
                    }}
                    id="dropdownTwo"
                    data-toggle={toggle ? "dropdown" : null}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About us
                  </a>
                  <div
                    className="dropdown-list dropdown-menu"
                    aria-labelledby="dropdownOne"
                  >
                    <a className="Vision" href={returnWPUrl.wpUrl + "about-us"}>
                      Mission, Purpose & Vision
                    </a>
                    <a
                      className="Faith"
                      href={returnWPUrl.wpUrl + "statement-of-faith/"}
                    >
                      Statement-of-Faith
                    </a>
                    <Link className="timeLine" to="/history">
                      History of the company
                    </Link>
                    <a
                      className="staff"
                      href={returnWPUrl.wpUrl + "about-us/meet-the-team"}
                    >
                      Meet the Team
                    </a>
                    <a
                      className="Employment"
                      href={returnWPUrl.wpUrl + "careers/"}
                    >
                      Employment
                    </a>
                    <a
                      className="president"
                      href={
                        returnWPUrl.wpUrl + "category/word-from-the-president"
                      }
                    >
                      Word From the President
                    </a>
                  </div>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
                <li className="nav-item dropdown-box">
                  <a
                    href={returnWPUrl.wpUrl + "advertise"}
                    className="nav-link dropdown-link advertise-btn"
                  >
                    Advertise
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <Location>
        {({ location }) => (
          <div
            className={returnSearchFormClass(location.pathname)}
            id="searchForm"
          >
            <SearchFormHead
              path={props.path}
              city={props.city}
              searchSlug={props.searchSlug}
            />
          </div>
        )}
      </Location>

      {typeof window !== "undefined" &&
        window.addEventListener("visibilitychange", handleVisibilityChange)}
    </>
  );
}
