import settings from "../settings.json";
const axios = require("axios");

export default function getListingData(query) {
  return axios({
    url: settings.apiURL,
    method: "post",
    data: {
      query: `{
        searchAutoSuggest(name: "${query}"){
          id
          name
          type
          slug
        }
      }`,
    },
  })
    .then((res) => {
      let searchArr = [];
      let categoryArr = {
        label: "Category",
        options: [],
      };

      let businessArr = {
        label: "Business",
        options: [],
      };

      const keywordOption = {
        value: `${query}`,
        label: `${query}`,
        slug: `${query}`,
        keyword: true,
      };

      res.data.data.searchAutoSuggest.map((item) => {
        if (item.type.toLowerCase() === "category") {
          categoryArr.options.push({
            value: `${item.name}`,
            label: `${item.name}`,
            slug: `${item.slug}`,
          });
        } else {
          businessArr.options.push({
            value: `${item.name}`,
            label: `${item.name}`,
            slug: `${item.slug}`,
          });
        }
      });

      searchArr.push(keywordOption);
      if (categoryArr.options.length) {
        searchArr.push(categoryArr);
      }
      if (businessArr.options.length) {
        searchArr.push(businessArr);
      }

      return searchArr;
    })
    .catch((err) => {
      return err.message;
    });
}
