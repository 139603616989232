/**
 * Wait a little for the user to type the query
 * @param {number} delay - the time in miliseconds
 * @param {*} request - the code that needs to be debounced
 */
//   const debounceRequest = (delay, request) => {
//   //  TODO: optimize this function to not make request for each charactor
//   let timer = setTimeout(() => {}, 1);

//   clearTimeout(timer);
//   timer = setTimeout(() => {
//       request()
//   }, delay)
// }

//write a throttling function
const throttleRequest = (delay, request) => {
  let timer = setTimeout(() => {}, 1);
  clearTimeout(timer);
  timer = setTimeout(() => {
    request();
  }, delay);
};

export default throttleRequest;
