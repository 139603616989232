import React from "react";
import FormInner from "./forminner";

export default function SearchFormFoot(props) {
  return (
    <FormInner
      path={props.path}
      city={props.city}
      searchSlug={props.searchSlug}
    />
  );
}
