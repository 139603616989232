import React, { useState, useEffect, useContext } from "react";
import Downshift from "downshift";
import settings from "../settings.json";
import getSearchData from "./getSearchData";
import { waitForKeypress } from "./utils";
import { GlobalDispatchContext } from "../../store/GlobalContextProvider";
import space from "../../helper/space";
import { checkCityName } from "../../helper/setCityAndState";
import "font-awesome/css/font-awesome.min.css";
const axios = require("axios");

export default function LocationSearchComponent(props) {
  const {
    placeholder,
    checkChange,
    label,
    formattedCityName,
    reset,
    setDefaultValue,
    defaultValue,
    error,
    unSetError,
  } = props;
  const [items, setItems] = useState([]);
  const [focus, setfocus] = useState(false);
  const [selected, setSelected] = useState(false);
  const dispatch = useContext(GlobalDispatchContext);
  const [searchValue, setSearchValue] = useState();
  const [initialLoad, setInitalLoad] = useState(true);

  function showPosition(position) {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    axios
      .post(settings.apiURL, {
        query: `
      query GetCity($lat: String!, $lng: String!) {
        getCity(lat: $lat, lng: $lng) {
          city
          lat
          lng
          state_code
          zip
        }
      }
    `,
        variables: {
          lat: lat.toString(),
          lng: lng.toString(),
        },
      })
      .then((res) => {
        let data = res.data.data.getCity;
        let finalValue = {
          city: data.city,
          state: data.state_code ? data.state_code : "",
          zipcode: data.zip ? data.zip : "",
          lat: data.lat,
          lng: data.lng,
        };

        localStorage.setItem(
          "city_name",
          finalValue.city + ", " + finalValue.state
        );
        localStorage.setItem("state_code", finalValue.state);
        localStorage.setItem("new_zipcode", finalValue.zipcode);
        localStorage.setItem(
          "new_city_name",
          finalValue.city + ", " + finalValue.state
        );
        localStorage.setItem("new_state_code", finalValue.state);
        localStorage.setItem("lat", finalValue.lat);
        localStorage.setItem("lng", finalValue.lng);


        // split city and state 
        let cityNameAndStateCode = localStorage.getItem("city_name");
        let stateCode = cityNameAndStateCode.split(",")[1].trim();
        let cityName = cityNameAndStateCode.split(",")[0].trim();

        axios({
          url: settings.apiURL,
          method: "post",
          data: {
            query: `{
              log(city: "${
                cityName + ", " + stateCode
              }" ,lat: "${lat}" ,lng: "${lng}"){		
                id		
              }		
            }`,
          },
        });

        getLocationString(finalValue);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function defaultLocation(position) {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;

    axios
      .post(settings.apiURL, {
        query: `
      query GetCity($lat: String!, $lng: String!) {
        getCity(lat: $lat, lng: $lng) {
          city
          lat
          lng
          state_code
          zip
        }
      }
    `,
        variables: {
          lat: lat.toString(),
          lng: lng.toString(),
        },
      })
      .then((res) => {
        let data = res.data.data.getCity;
        let finalValue = {
          city: data.city,
          state: data.state_code ? data.state_code : "",
          zipcode: data.zip ? data.zip : "",
          new_city_name: data.city,
          new_state_code: data.state_code ? data.state_code : "",
          lat: data.lat,
          lng: data.lng,
        };

        localStorage.setItem(
          "city_name",
          finalValue.city + ", " + finalValue.state
        );
        localStorage.setItem("state_code", finalValue.state);
        localStorage.setItem("new_zipcode", finalValue.zipcode);
        localStorage.setItem(
          "new_city_name",
          finalValue.new_city_name + ", " + finalValue.state
        );
        localStorage.setItem("new_state_code", finalValue.new_state_code);
        localStorage.setItem("lat", finalValue.lat);
        localStorage.setItem("lng", finalValue.lng);
 
        // split city and state 
        let cityNameAndStateCode = localStorage.getItem("city_name");
        let stateCode = cityNameAndStateCode.split(",")[1].trim();
        let cityName = cityNameAndStateCode.split(",")[0].trim();

        axios({
          url: settings.apiURL,
          method: "post",
          data: {
            query: `{
              log(city: "${
                cityName + ", " + stateCode
              }" ,lat: "${lat}" ,lng: "${lng}"){		
                id		
              }		
            }`,
          },
        });

        getLoc(finalValue);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getLoc = (finalValue) => {
    if (localStorage.getItem("city_name")) {
      setDefaultValue(localStorage.getItem("city_name"));
    } else {
      setDefaultValue(formattedCityName || space(`${finalValue.city}`));
    }
  };

  const getLocationString = (finalValue = null) => {
    let locationString =
      localStorage.getItem("new_city_name") &&
      localStorage.getItem("new_city_name") !== "null"
        ? localStorage.getItem("new_city_name")
        : localStorage.getItem("city_name");

    if (formattedCityName) {
      setDefaultValue(formattedCityName || space(`${finalValue.city}`));
    } else {
      if (checkCityName(locationString)) {
        setDefaultValue(`${locationString}`);
        setSelected(true);
      } else if (checkCityName(formattedCityName)) {
        setDefaultValue(formattedCityName);
        setSelected(true);
      }
    }
  };

  const getDefaultLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        defaultLocation,
        (error) => {
          console.log("error ran", error);
          setDefaultValue("");
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("city_name") == null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          showPosition,
          (error) => {
            console.log("error ran", error);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } else {
      getLocationString();
    }
  }, []);

  const handleLocChange = (inputValue) => {
    setDefaultValue(space(`${inputValue?.label}`));
    localStorage.setItem("new_city_name", space(inputValue?.label));
    localStorage.setItem("new_zipcode", inputValue?.zipcode);
    localStorage.setItem("new_state_code", inputValue?.label?.split(",")[1]);
    localStorage.setItem("lat", inputValue?.lat);
    localStorage.setItem("lng", inputValue?.lng);
    dispatch({ type: "locationChange" });
    dispatch({ type: "latitudeandlongitude", latitude: "", longitude: "" });
    setfocus(false);
    setSelected(true);
    checkChange(true);
    setSearchValue(`${inputValue?.label}`);
  };

  return (
    <div className="search-component location-search">
      <Downshift
        isOpen={focus}
        onInputValueChange={(inputValue) => {
          if (inputValue == "" && initialLoad) {
            setDefaultValue(
              formattedCityName ||
                space(localStorage.getItem("new_city_name")) ||
                localStorage.getItem("city_name")
            );
            return;
          }

          if (!initialLoad && inputValue.length <= 0) {
            setDefaultValue("");
          }
          if (initialLoad || inputValue == "") {
            setInitalLoad(false);
            // return;
          }

          if (searchValue == defaultValue) {
            return;
          }
          setSelected(false);
          setfocus(true);
          //remove spaces from input value
          let inputValueNoSpace = inputValue?.replace(/\s/g, "");
          //remove spaces from default value
          let defaultValueNoSpace = searchValue?.replace(/\s/g, "");

          if (searchValue) {
            if (inputValueNoSpace === defaultValueNoSpace) {
              setfocus(false);
              return;
            }
          }

          setDefaultValue(inputValue);
          if (inputValue.length < 1) {
            return;
          }

          waitForKeypress(100, () =>
            getSearchData(inputValue).then((result) => {
              if (typeof result === "object") {
                setItems(result);
              } else {
                setItems([]);
              }
            })
          );
        }}
        itemToString={(item) => (item ? item.label : "")}
        inputValue={defaultValue}
        onChange={handleLocChange}
      >
        {({
          getInputProps,
          getItemProps,

          getMenuProps,
          clearSelection,
          isOpen,

          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => {
          return (
            <>
              <div
                className="search-component__input-container"
                {...getRootProps({}, { suppressRefError: true })}
              >
                <label>{label}</label>
                {error && <p className="form__error-msg">Required field!</p>}
                <input
                  {...getInputProps({
                    autoComplete: "off",
                    onFocus: () => {
                      setfocus(true);
                      unSetError();
                    },
                    onBlur: () => {
                      setfocus(false);

                      if (!selected) {
                        setDefaultValue("");
                      }
                    },
                  })}
                  className="search-component__input"
                  placeholder={placeholder}
                  tabIndex={1}
                />
                {defaultValue?.length > 1 ? (
                  <button
                    onClick={() => {
                      setDefaultValue("");
                    }}
                  >
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                      tabIndex={-1}
                    ></i>
                  </button>
                ) : null}

                {reset && (
                  <span
                    className="search-component__reset"
                    onClick={() => {
                      getDefaultLocation();
                    }}
                  >
                    Refresh to browser location
                  </span>
                )}
              </div>

              <ul className="search-component__results" {...getMenuProps()}>
                {isOpen && defaultValue?.length >= 1 ? (
                  <>
                    {!selected ? (
                      <span className="search-component__results-label">
                        Pick a location
                      </span>
                    ) : null}

                    {items?.map((item, index) => (
                      <li
                        className="search-component__result"
                        {...getItemProps({
                          key: `${item.value}${item.zipcode}`,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index
                                ? "lightgray"
                                : "white",
                            fontWeight:
                              selectedItem === item ? "bold" : "normal",
                          },
                        })}
                      >
                        {space(`${item.label}`)}
                      </li>
                    ))}
                  </>
                ) : null}
              </ul>
            </>
          );
        }}
      </Downshift>
    </div>
  );
}
